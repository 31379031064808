@font-face {
  font-family: myFirstFont;
  src: url("../../Tahu.ttf");
}


#hero {
  background-image: url("../../img/mountain.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

#big-logo {
  margin-left: 42%;
  margin-top: 40vh;
}

#big-company-name {
  font-size: 70px;
  color: white;
  font-family: 'Tahu!';
}
